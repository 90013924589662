<template>
    <v-container style="min-height: 100vh;" class="pa-0 ma-0" fluid>
<!--        <admin-sessions v-if="$store.getters.userHasRole('admin')"></admin-sessions>-->
        <CounselorSessionsNewDesign v-if="$store.getters.userHasRole('counselor')"></CounselorSessionsNewDesign>
        <ClientAwaitingSessions v-if="$store.getters.userHasRole('customer')"></ClientAwaitingSessions>
    </v-container>
</template>

<script>
    // import AdminSessions from '@/components/sessions/AdminSessions.vue'
    // import CounselorSessions from '@/components/sessions/CounselorSessions.vue'
    // import ClientSessions from '@/components/sessions/ClientSessions.vue'
    import ClientAwaitingSessions from '@/components/sessions/clientAwaitingSessions.vue'
    import CounselorSessionsNewDesign from '@/components/sessions/CounselorSessionsNewDesign.vue'

    export default {
        components: {
            // AdminSessions,
            // CounselorSessions,
            // ClientSessions,
            ClientAwaitingSessions,
            CounselorSessionsNewDesign
        }
    }
</script>

<style scoped>

</style>